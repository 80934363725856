import {AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {Autorizacion} from '../../_models/autorizacion.model';
import {RestApiService} from '../../_services/rest-api.service';
import {UIService} from '../../_services/ui.service';
import {AuthorizationService} from '../../_services/authorization.service';
import {OnboardingService} from '../../_services/onboarding-steps.service';
import {DocumentAttached} from '../../_models/document-attached.model';
import {OnboardingStep} from '../../_enums/onboarding-step.enum';
import {Cliente} from '../../_models/cliente.model';
import {ClienteService} from '../../_services/cliente.service';
import {HeaderStep} from '../../_enums/header-step.enum';
import {BehaviorSubject, Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {WithdrawalMethods} from '../../_enums/withdrawal-methods.enum';
import {CreditType} from 'src/app/_enums/credit-type.enum';
import {HttpErrorResponse} from '@angular/common/http';
import {TitleService} from '../../_services/title.service';
import {Ng2ImgMaxService} from 'ng2-img-max';

import {StyleServiceService} from 'src/app/_services/style-service.service';
import {Step} from '../../_enums/step.enum';
import {LogType} from '../../_enums/log-type.enum';

@Component({
	selector: 'app-aprobacion',
	templateUrl: './aprobacion.component.html',
	styleUrls: ['./aprobacion.component.css'],
})
export class AprobacionComponent implements OnInit {
	public useCredilowStyle: boolean;
	@Output() public changeStepperState: EventEmitter<HeaderStep> = new EventEmitter<HeaderStep>();

	@ViewChild('goToTop') goToTop: ElementRef;

	public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public comentario: string;
	public autorizacion: Autorizacion = new Autorizacion();
	public documentacionServicios: BehaviorSubject<DocumentAttached[]> = new BehaviorSubject<DocumentAttached[]>([]);
	public documentacionAdicional: BehaviorSubject<DocumentAttached[]> = new BehaviorSubject<DocumentAttached[]>([]);

	private cliente: Cliente = new Cliente();
	private clienteServiceSubscription: Subscription;

	constructor(
		public styleService: StyleServiceService,

		private readonly restApiService: RestApiService,
		private readonly autorizacionService: AuthorizationService,
		private readonly uIService: UIService,
		private readonly onboardingService: OnboardingService,
		private readonly clienteService: ClienteService,
		private titleService: TitleService,
		private ng2ImgMax: Ng2ImgMaxService
	) {}

	public ngOnInit(): void {
		this.styleService.useCredilowStyle.subscribe((useCredilowStyle) => {
			this.useCredilowStyle = useCredilowStyle;
		});
		this.titleService.updateTitle('Aprobación');
		this.autorizacionService.getAutorizacion().subscribe({
			next: (autorizacion: Autorizacion) => (this.autorizacion = autorizacion),
			error: (error: Error) => console.log(error),
		});
		this.clienteServiceSubscription = this.clienteService.getCliente().subscribe({
			next: (cliente: Cliente) => {
				this.cliente = cliente;
				this.restApiService
					.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.StartAprobacionCheck, logType: LogType.Flujo})
					.subscribe(() => {
						this.unsubscribe();
					});
			},
			error: (error: Error) => console.log(error),
		});
	}

	private unsubscribe(): void {
		if (this.clienteServiceSubscription && !this.clienteServiceSubscription.closed) {
			this.clienteServiceSubscription.unsubscribe();
		}
	}

	public ngAfterViewInit() {
		this.goToTop.nativeElement.scrollIntoView();
	}

	public confirm(): void {
		if (!this.checkUploadedServices()) {
			return;
		}
		this.loading.next(true);
		this.restApiService
			.confirmarPlan(
				this.autorizacion.id,
				this.autorizacion.productoAdquirido,
				WithdrawalMethods['Retirar en el momento'],
				this.autorizacion.planSeleccionado.Id,
				this.cliente.identityId,
				this.cliente.validationId,
				this.comentario
			)
			.pipe(finalize(() => this.loading.next(false)))
			.subscribe({
				next: (data: any) => {
					if (data.status == 'error') {
						this.restApiService
							.monitoringLog({
								creditFlowId: this.cliente.creditFlowId,
								step: Step.PostSeleccionPlanAC,
								logType: LogType.Consola,
								error: data,
							})
							.subscribe();
						this.uIService.responseLog('Error', data);
						this.uIService.successMessage('Error!', data);
					} else {
						this.restApiService
							.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.PostSeleccionPlanAC, logType: LogType.Flujo})
							.subscribe();
						this.uIService.responseLog('confirmarPlan - OK', data);
						this.onboardingService.moveTo(OnboardingStep.Sign);
					}
				},
				error: (error: HttpErrorResponse) => {
					this.restApiService
						.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.PostSeleccionPlanAC, logType: LogType.Consola, error})
						.subscribe();
					this.uIService.responseLog('confirmarPlan - ERROR', error);
					this.uIService.errorMessage('Error!', error.error.message);
				},
			});
	}

	public cancel(): void {
		this.restApiService.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.Cancel, logType: LogType.Flujo}).subscribe();
		if (this.clienteServiceSubscription) {
			this.clienteServiceSubscription.unsubscribe();
		}
		this.onboardingService.restart();
		this.clienteService.restart();
	}

	public uploadFileDocumentacionServicios(event: any): void {
		this.loading.next(true);
		this.restApiService
			.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.StartSubirServicio, logType: LogType.Flujo})
			.subscribe();

		if (event.target.files.length > 0) {
			const file = event.target.files[0];
			// Resize and compress the image
			this.ng2ImgMax.resizeImage(file, 800, 600)
				.pipe(finalize(() => this.loading.next(false)))
				.subscribe({
					next: (result) => {
						const convertedFile = new File([result], file.name, {type: file.type});
						const documentAttached = new DocumentAttached('servicio', convertedFile);
						this.adjuntarServicio(documentAttached);
					},
					error: (error) => {
						this.loading.next(false);
						this.restApiService
							.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.SubirServicio, logType: LogType.Consola, error})
							.subscribe();
						this.uIService.responseLog('uploadFileDocumentacionServicios - ERROR', error);
						this.uIService.errorMessage('Error!', null);
					},
				});
		}
	}

	public uploadFileDocumentacionAdicional(event: any): void {
		this.loading.next(true);
		this.restApiService
			.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.StartSubirAdicional, logType: LogType.Flujo})
			.subscribe();

		if (event.target.files.length > 0) {
			const file = event.target.files[0];
			// Resize and compress the image
			this.ng2ImgMax.resizeImage(file, 800, 600)
				.pipe(finalize(() => this.loading.next(false)))
				.subscribe({
					next: (result) => {
						const convertedFile = new File([result], file.name, {type: file.type});
						const documentAttached = new DocumentAttached('adicional', convertedFile);
						this.adjuntarAdicional(documentAttached);
					},
					error: (error) => {
						this.restApiService
							.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.SubirAdicional, logType: LogType.Consola, error})
							.subscribe();
						this.uIService.responseLog('uploadFileDocumentacionAdicional - ERROR', error);
						this.uIService.errorMessage('Error!', null);
					},
				});
		}
	}

	public adjuntarServicio(documentacion: DocumentAttached): void {
		this.loading.next(true);
		this.restApiService
			.adjuntarServicio(documentacion, this.autorizacion.id)
			.pipe(finalize(() => this.loading.next(false)))
			.subscribe({
				next: (data: any) => {
					this.restApiService
						.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.SubirServicio, logType: LogType.Flujo})
						.subscribe();
					this.uIService.responseLog('adjuntarServicios - OK', data);
					this.documentacionServicios.next(this.documentacionServicios.value.concat([documentacion]));
				},
				error: (error: HttpErrorResponse) => {
					this.restApiService
						.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.SubirServicio, logType: LogType.Consola, error})
						.subscribe();
					this.uIService.responseLog('adjuntarServicios - ERROR', error);
					this.uIService.errorMessage('Error!', error.error.message);
				},
			});
	}

	public adjuntarAdicional(documentacion: DocumentAttached): void {
		this.loading.next(true);
		this.restApiService
			.adjuntarAdicional(documentacion, this.autorizacion.id)
			.pipe(finalize(() => this.loading.next(false)))
			.subscribe({
				next: (data: any) => {
					this.restApiService
						.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.SubirAdicional, logType: LogType.Flujo})
						.subscribe();
					this.uIService.responseLog('adjuntarAdicional - OK', data);
					this.documentacionAdicional.next(this.documentacionAdicional.value.concat([documentacion]));
				},
				error: (error: HttpErrorResponse) => {
					this.restApiService
						.monitoringLog({creditFlowId: this.cliente.creditFlowId, step: Step.SubirAdicional, logType: LogType.Consola, error})
						.subscribe();
					this.uIService.responseLog('adjuntarAdicional - ERROR', error);
					this.uIService.errorMessage('Error!', error.error.message);
				},
			});
	}

	public borrarDocumento(documentos: BehaviorSubject<DocumentAttached[]>, index: number): void {
		documentos.value.splice(index, 1);
	}

	private checkUploadedServices() {
		if (!this.cliente.renovador) {
			if (this.autorizacion.tipoCredito === CreditType.PlanDNI && this.documentacionServicios.value.length < 1) {
				this.uIService.errorMessage('Error', 'Debe subir al menos 1 servicio');
				return false;
			}
			if (this.autorizacion.tipoCredito === CreditType.DNI2Servicios && this.documentacionServicios.value.length < 2) {
				this.uIService.errorMessage('Error', 'Debe subir al menos 2 servicios');
				return false;
			}
		}
		return true;
	}
}
