<div class="container text-center">
	<div class="credilow-navigate space-between-buttons mb-3">
		<div class="credilow-50">
			<button class="credilow-next-button credilow-shadow" [ngClass]="{sanii: !useCredilowStyle}" (click)="getCreditos()">
				Actualizar
			</button>
		</div>
		<div class="credilow-50">
			<button class="credilow-previous-button credilow-shadow" [ngClass]="{sanii: !useCredilowStyle}" (click)="goBack()">Volver</button>
		</div>
	</div>
	<div *ngIf="commerces; else loadingCommerce">
		<div *ngIf="commerces.length === 0; else optionsCommerces">
			<div class="credilow-plan-cuota disabled">No se han encontrado comercios</div>
		</div>
		<ng-template #optionsCommerces>
			<mat-form-field class="commerceSelect">
				<mat-label>Comercios</mat-label>
				<mat-select [(value)]="comercioSelected" disabled="{{ disabled }}">
					<mat-option *ngFor="let commerce of commerces; index as i" value="commerce{{ i }}" [value]="commerce">
						<small>{{ commerce.nombre }}</small>
					</mat-option>
				</mat-select>
			</mat-form-field>
			<div *ngIf="comercioSelected">
				<div *ngFor="let vendedor of comercioSelected.vendedores; index as i">
					<hr />
					<h3 style="text-align: center">{{ vendedor.nombre }}</h3>
					<hr />
					<div *ngFor="let credito of vendedor.creditos; index as j">
						<button (click)="toggleCredit(i, j)" id="bt" class="w-100 p-0 preview-credit">
							<div class="d-flex flex-row">
								<table class="w-100 text-left">
									<tr>
										<th>Monto</th>
										<td class="text-right">
											${{ credito.monto }}
											<i class="material-icons float-right">keyboard_arrow_down</i>
										</td>
									</tr>
									<tr>
										<th>Fecha</th>
										<td class="text-right">{{ credito.fecha }}</td>
									</tr>
									<tr>
										<th>Estado</th>
										<td class="text-right">
											<span
												[ngClass]="{
													'btn-warning': credito.estado === 'Pendiente' || credito.estado === 'Aprobada a recepcionar',
													'btn-success': credito.estado === 'Aprobada',
													'btn-danger': credito.estado === 'Rechazada'
												}"
											>
												{{ credito.estado }}
											</span>
										</td>
									</tr>
								</table>
							</div>
							
							<ng-container *ngIf="indexi === i && indexj === j">
								<div style="margin: 0 auto; text-align: left">
									<table class="w-100">
										<tr>
											<th>N°</th>
											<td class="text-right">{{ credito.numero }}</td>
										</tr>
										<tr>
											<th>Documento</th>
											<td class="text-right">{{ credito.documento }}</td>
										</tr>
										<tr>
											<th>Documentación</th>
											<td class="text-right">
												{{ credito.documentacionRequerida ? 'Si' : 'No'}}
											</td>
										</tr>
										<tr>
											<th>Producto Adquirido</th>
											<td class="text-right">
												{{ credito.productoAdquirido }}
											</td>
										</tr>
									</table>
									<div class="d-flex flex-wrap justify-content-start align-items-center  mt-1 mb-2">
										<div class=" col-6 flex-grow-1 text-center p-2" >
											<button
												[disabled]="!credito.identityServiceId"
												style="margin: 0px !important;width: 100%;"
												class="m-3 small-btn modify-btn p-2 credilow-shadow"
												(click)="getNeutralSelfie(credito.identityServiceId)"
											>
												{{ credito.identityServiceId ? 'Ver Selfie' : 'Selfie No Disponible' }}
											</button>
										</div>
										<div class=" col-6  flex-grow-1 text-center p-2 " *ngIf="showFirmaEnPapel(credito)">
											<button
												class="m-3 small-btn modify-btn p-2 credilow-shadow"
												style="margin: 0px !important;width: 100%;"
												(click)="signCallCenter(credito.idAprobacion)"
											>
												Firma en papel
											</button>
										</div>
										<div class=" col-6 flex-grow-1 text-center p-2 " *ngIf="showSendMutuo(credito)">
											<button
												style="margin: 0px !important;width: 100%;"
												class="m-3 small-btn modify-btn p-2 credilow-shadow"
												(click)="sendMutuoToVendedor(credito.idAprobacion)"
											>
												Reenviar mutuo
											</button>
										</div>
										<div class=" col-6 flex-grow-1 text-center p-2 " *ngIf="showDocusign(credito)">
											<button
												style="margin: 0px !important;width: 100%;"
												class="m-3 small-btn modify-btn p-2 credilow-shadow"
												(click)="docusign(credito.idAprobacion, credito.validationId)"
											>
												Docusign
											</button>
										</div>

										<div class="col-6 text-center p-2 " *ngIf="showAditionalDocuments(credito)">
											<button
												style="margin: 0px !important;word-wrap: normal;width: 100%;"
												class="m-3 small-btn modify-btn p-2 credilow-shadow"
												(click)="uploaderDocumentacionAdicional.click()"
											>
												Cargar Documentación
											</button>
											
										</div>
										
									</div>
								</div>
							</ng-container>
							<input
								#uploaderDocumentacionAdicional
								type="file"
								accept="image/*"
								capture="environment"
								hidden
								(change)="uploadFileDocumentacionAdicional($event, credito.idAprobacion)"
							/>
						</button>
					</div>
				</div>
			</div>
		</ng-template>
	</div>
	<ng-template #loadingCommerce>
		<div class="credilow-plan-cuota disabled">Cargando...</div>
	</ng-template>
</div>
<app-loader *ngIf="loading | async"></app-loader>
